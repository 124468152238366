import React, { useEffect } from "react";
import Meta from "../../components/Meta";
import Download from "../../components/blog/download";
import {
  Auctions_categories,
  Bids,
  Browse_category,
} from "../../components/component";
import Collection_category from "../../components/collectrions/collection_category";
import Hero_6 from "../../components/hero/hero_6";
import { useDispatch, useSelector } from "react-redux";
import { fetchCollections } from "../../redux/getCollections";
import { fetchCategory } from "../../redux/getCategory";
import { fetchArtifacts } from "../../redux/getArtifacts";

const Home_6 = () => {

  const dispatch = useDispatch();
  const { data, loading } = useSelector(state => state.getCollections);
  const { categoryError, categoryLoading, categoryData } = useSelector(state => state.getCategory);
  const { artifactError, artifactLoading, artifactData } = useSelector(state => state.getArtifact);

  useEffect(() => {
    if (data?.length === 0) {
      dispatch(fetchCollections());
    }
    if (categoryData?.length === 0) {
      dispatch(fetchCategory());
    }
    if (artifactData?.length === 0) {
      dispatch(fetchArtifacts());
    }
  }, []);

  return (
    <>
      <Meta title="Bitdenex NFT Marketplace" />
      <Hero_6
        data={data}
        loading={loading}
      />
      <Bids
        artifactData={artifactData}
        artifactLoading={artifactLoading}
      />
      <Collection_category
        bgWhite={true}
        categoryData={categoryData}
        data={data}
        artifactData={artifactData}
      />
      <Auctions_categories
        artifactData={artifactData}
      />
      <Browse_category
        bgWhite={true}
        categoryData={categoryData}
      />
      <Download />
    </>
  );
};

export default Home_6;
