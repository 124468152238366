// import Link from "next/link";
import React from "react";
import { Skeleton } from "antd";
import { useRouter } from "next/router";
import requestHandler from "../../redux/httpClient";
import Link from "next/link";
import Image from "next/image";

const loadingData = [1, 2, 3, 4];

const Hero_6 = ({ data, loading }) => {
  const router = useRouter();

  const getRandomData = (array, size) => {
    const shuffledArray = array?.slice().sort(() => Math.random() - 0.5);
    return shuffledArray?.slice(0, size);
  };

  const randomData = getRandomData(data, 9);

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="relative px-6 pb-8 py-24 md:pt-32">
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="w-full lg:w-1/3">
            <div className="grid grid-cols-2 grid-rows-2 gap-5">
              {
                loading ?
                  loadingData?.map((index) => {
                    return <Skeleton.Image active
                      key={index}
                      style={{
                        width: 280.5,
                        height: 280.5,
                        objectFit: 'cover'
                      }}
                    />
                  })
                  :
                  randomData?.slice(0, 4).map((item, index) => {
                    const { id, name: title, logo: img, brandName: authorName } = item;
                    const formattedTitle = title?.replace(/\s+/g, '-');
                    return (
                      <article key={index}>
                        <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                          <figure className="relative">
                            <Link
                              href={{
                                pathname: `/collection/${id}`,
                              }}
                              className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20"
                            >
                              <Image
                                width={470}
                                height={470}
                                // src={`${process.env.NEXT_PUBLIC_AWS_URL}/${img}`}
                                src={`${process.env.NEXT_PUBLIC_AWS_URL}/${item?.artifacts[0]?.image}`}
                                alt={title}
                                className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105"
                              />
                            </Link>
                          </figure>
                          <div className="pointer-events-none absolute bottom-0 w-full p-5">
                            <h2 className="font-display text-base leading-none text-white xl:text-lg">
                              {title}
                            </h2>
                            <span className="text-2xs text-white">
                              By {authorName}
                            </span>
                          </div>
                        </div>
                      </article>
                    );
                  })}
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            {
              loading
                ?
                loadingData?.slice(0, 1)?.map((index) => {
                  return <Skeleton.Image active
                    key={index}
                    style={{
                      width: 581.67,
                      height: 581.67,
                      objectFit: 'cover'
                    }}
                  />
                })
                :
                randomData?.slice(4, 5).map((item, index) => {
                  const { id, name: title, logo: img, brandName: authorName } = item;
                  return (
                    <article key={index}>
                      <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                        <figure className="relative">
                          <Link
                            className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20"
                            href={{
                              pathname: `/collection/${id}`,
                            }}
                          // onClick={() => {
                          //   const formattedTitle = title?.replace(/\s+/g, '-');
                          //   router.push({
                          //     pathname: `/collection/${formattedTitle}`,
                          //     query: { id: "amit" }
                          //   });
                          // }}
                          >
                            <Image
                              width={470}
                              height={470}
                              // src={`${process.env.NEXT_PUBLIC_AWS_URL}/${img}`}
                              alt={title}
                              src={`${process.env.NEXT_PUBLIC_AWS_URL}/${item?.artifacts[0]?.image}`}
                              className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105"
                            />
                          </Link>
                        </figure>
                        <div className="pointer-events-none absolute bottom-0 w-full p-5">
                          <h2 className="font-display text-base leading-none text-white xl:text-lg">
                            {title}
                          </h2>
                          <span className="text-2xs text-white">
                            By {authorName}
                          </span>
                        </div>
                      </div>
                    </article>
                  );
                })}
          </div>
          <div className="w-full lg:w-1/3">
            <div className="grid grid-cols-2 grid-rows-2 gap-5">
              {
                loading ?
                  loadingData?.map((index) => {
                    return <Skeleton.Image
                      key={index}
                      active
                      style={{
                        width: 280.5,
                        height: 280.5,
                        objectFit: 'cover'
                      }}
                    />
                  })
                  :
                  randomData?.slice(5, 9).map((item, index) => {
                    const { id, name: title, logo: img, brandName: authorName } = item;
                    return (
                      <article key={index}>
                        <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                          <figure className="relative">
                            <Link
                              className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20"
                              // onClick={() => {
                              //   const formattedTitle = title?.replace(/\s+/g, '-');
                              //   router.push({
                              //     pathname: `/collection/${formattedTitle}`,
                              //     query: { id: "amit" }
                              //   });
                              // }}
                              href={{
                                pathname: `/collection/${id}`,
                              }}
                            >
                              <Image
                                width={470}
                                height={470}
                                // src={`${process.env.NEXT_PUBLIC_AWS_URL}/${img}`}
                                src={`${process.env.NEXT_PUBLIC_AWS_URL}/${item?.artifacts[0]?.image}`}
                                alt={title}
                                className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105"
                              />
                            </Link>
                          </figure>
                          <div className="pointer-events-none absolute bottom-0 w-full p-5">
                            <h2 className="font-display text-base leading-none text-white xl:text-lg">
                              {title}
                            </h2>
                            <span className="text-2xs text-white">
                              By {authorName}
                            </span>
                          </div>
                        </div>
                      </article>
                    );
                  })}
            </div>
          </div>
        </div>
      </section >
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_6;
