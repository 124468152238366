import Image from "next/image";
import Link from "next/link";
import React from "react";

const Collection_category = ({ bgWhite = false, categoryData, artifactData, data }) => {

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    } else {
      return str;
    };
  };

  return (
    <div>
      {/* <!-- Today's Drops / Sellers / Buyers --> */}
      <section className="py-24 relative">
        {bgWhite && (
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <Image
              width={1519}
              height={773}
              priority
              src="/images/gradient_light.jpg"
              alt="gradient"
              className="h-full w-full object-cover"
            />
          </picture>
        )}
        <div className="container">
          <div className="flex flex-col space-y-5 lg:flex-row lg:space-y-0 lg:space-x-7">
            <div className="dark:bg-jacarta-800 bg-light-base rounded-2.5xl p-12 lg:w-1/3">
              <h2 className="text-jacarta-700 font-display mb-8 text-center text-3xl font-semibold dark:text-white">
                Latest Drop's
              </h2>
              <div className="flex flex-col space-y-5">
                {data?.slice(0, 5)?.map((item, index) => {
                  // const { id, name: title, logo: img, network: authorName, walletAddress: walletAddress } = item;
                  return (
                    <Link
                      href={`collection/${item?.id}`}
                      key={index}
                      className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent"
                    >
                      <figure className="mr-4 shrink-0">
                        <div
                          style={{ cursor: 'pointer' }}
                          className="relative block"
                        >
                          <img
                            width={48}
                            height={48}
                            src={`${process.env.NEXT_PUBLIC_AWS_URL}/${item?.logo}`}
                            // src={item?.logo}
                            alt={item?.name}
                            className="rounded-2lg h-12 w-12"
                          />
                          {/* <div className="dark:border-jacarta-600 bg-jacarta-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white text-xs text-white">
                            {"item?.ArtifactsModels?.length"}
                          </div> */}
                        </div>
                      </figure>
                      <div>
                        <div
                          style={{ cursor: 'pointer' }}
                          className="block"
                        >
                          <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                            {item?.name}
                          </span>
                        </div>
                        <span className="dark:text-jacarta-300">
                          {item?.user?.userName != null || item?.user?.userName != undefined ? item?.user?.userName : <small>{truncateString(item?.walletAddress, 10)}</small>}
                        </span>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="dark:bg-jacarta-800 bg-light-base rounded-2.5xl p-12 lg:w-1/3">
              <h2 className="text-jacarta-700 font-display mb-8 text-center text-3xl font-semibold dark:text-white">
                Latest NFT's
              </h2>
              <div className="flex flex-col space-y-5">
                {artifactData?.slice(0, 5)?.map((item, index) => {
                  // const { id, image, name: title, price: price, network: { name: networkName }, } = item;
                  return (
                    <Link
                      href={`artifact/${item?.id}`}
                      key={index}
                      className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent"
                    >
                      <figure className="mr-4 shrink-0">
                        <div
                          style={{ cursor: 'pointer' }}
                          className="relative block"
                        >
                          <img
                            width={48}
                            height={48}
                            src={`${process.env.NEXT_PUBLIC_AWS_URL}/${item?.image}`}
                            alt={item?.name}
                            className="rounded-2lg h-12 w-12"
                          />
                        </div>
                      </figure>
                      <div>
                        <div
                          style={{ cursor: 'pointer' }}
                          className="block"
                        >
                          <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                            {item?.name}
                          </span>
                        </div>
                        <span className="dark:text-jacarta-300">
                          <small>{item?.price} {item?.collection?.network?.name?.toUpperCase()}</small>
                        </span>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="dark:bg-jacarta-800 bg-light-base rounded-2.5xl p-12 lg:w-1/3">
              <h2 className="text-jacarta-700 font-display mb-8 text-center text-3xl font-semibold dark:text-white">
                Top Categories
              </h2>
              <div className="flex flex-col space-y-5">
                {categoryData?.slice(0, 5)?.map((item, index) => {
                  const { id: id, image: image, name: title } = item;
                  return (
                    <Link
                      href={'/collection/explore_collection'}
                      key={index}
                      className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent"
                    >
                      <figure className="mr-4 shrink-0">
                        <div
                          style={{ cursor: 'pointer' }}
                          className="relative block"
                        >
                          <img
                            width={48}
                            height={48}
                            src={`${process.env.NEXT_PUBLIC_AWS_URL}/${image}`}
                            alt={title}
                            className="rounded-2lg h-12 w-12"
                          />
                        </div>
                      </figure>
                      <div>
                        <div
                          style={{ cursor: 'pointer' }}
                          className="block"
                        >
                          <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                            {title}
                          </span>
                        </div>
                        {/* <span className="dark:text-jacarta-300">
                          {amount}
                        </span> */}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end today's drops / sellers / buyers --> */}
    </div>
  );
};

export default Collection_category;
